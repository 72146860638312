import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import DropdownSelection from "./Components/DropdownSelection/DropdownSelection";
import { fetchDisciplineSpeciality } from "../../API/API";
import { useEffect } from "react";
import { updateSignupProfile, talentProfileApidata } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import Ratingmodal from "./Ratingmodal";
import ProgressBar from "../../components/RatingProgressBar/ProgressBar";

function DisciplineScreen() {
  const windowHeight = window.innerHeight-50;
  const history = useHistory();
  const dispatch = useDispatch();
  const [val, setVal] = useState('');
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const [discipline, setDiscipline] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading1 = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector((state) => state.profileReducer.signupUpdateSuccess);
  const [isModal , setisModal] = useState(true)



  useEffect(() => {
    if(apiSuccess && flag){
      setFlag(false);
      history.push("/speciality");
    }
  }, [apiSuccess])
  useEffect(() => {
    if(profiledata?.profile?.Discipline){
      setSelected({discipline:profiledata.profile.Discipline});
      setVal(profiledata.profile.Discipline);
    }
  }, [profiledata])
  
  const getDiscipline = async(e) => {
    setVal(e);
    error && setError(null);
    setLoading(true);
    const temp = await fetchDisciplineSpeciality({ search: e?.trim()?.length < 1 ? 'a' : e?.trim(),type:"discipline"});
    if(temp.data){
      setDiscipline(temp.data);
    }
    setLoading(false)
  }
  const nextHandler = () => {
    if(!selected || val===""){
      setError("Please add your discipline to continue")
    }else{
      setFlag(true);
      setError(null);
      dispatch(updateSignupProfile({
        "Profiles.Discipline":selected?.discipline ?? "",
        WizardFlow:103
      }))
    }
    // dispatch(talentProfileApidata());
  }

  return (
    <>
       {isModal? <Ratingmodal/> 
        : null} 
    <div className="NameScreenContainer" style={{minHeight:windowHeight}}>

      {typeof apiError === "string" && <Alert message={apiError} type="error" className="alert-error" showIcon closable/>}
      {profiledata?.profile?.ProfessionCode == "HC"?<div className='Profile-Completion-Parent'>
      <div className='ProfileCompletion'>
      <ProgressBar heading={"Tell us a bit about yourself"} />
      </div>
    </div> : 
     <ProfileCompletion currenPage={1} totalPage={10} title="Tell us a bit about yourself" />}
      <DropdownSelection
        title={"Your discipline"}
        placeholder={"ex. RN"}
        subTitle={"Type and pick a discipline"}
        type={"Mandatory"}
        value={val}
        onChange={getDiscipline}
        options={discipline}
        keyVal={"discipline"}
        selected={selected}
        setSelected={(e)=>{setSelected(e); setVal(e?.discipline); error && setError(null)}}
        error={error}
        loading={loading}
      />
      
      <BottomButtons 
        nextHandler={nextHandler}
        nextBtnLoading={loading1}
        backHandler={() => history.push('/effective-profile-info')} />       
    </div>
    </>
  );
}

export default DisciplineScreen;

import React, {useEffect,useState} from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import { fetchKeySearchLocation, updateSignupProfile } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import DropdownSelection from "./Components/DropdownSelection/DropdownSelection";
import ProgressBar from "../../components/RatingProgressBar/ProgressBar";
import { Alert } from "antd";

function LocationScreen() {
  const windowHeight = window.innerHeight-50;
  const history = useHistory();
  const dispatch = useDispatch();
  const searchedLocation = useSelector(
    (state) => state.credentialingReducer.searchedLocation
  );
  const searchLoading = useSelector(
    (state) => state.credentialingReducer.searchLoading
  );
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector((state) => state.profileReducer.signupUpdateSuccess);

  const [val, setVal] = useState('');
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);
 

  useEffect(() => {
    if(apiSuccess && flag){
      setFlag(false);
     history.push("/mobileno") 
      // history.push(profiledata?.profile.ProfessionCode === "IT" ? "/mobileno":"/discipline");
    }
  }, [apiSuccess])
  useEffect(() => {
    if(profiledata?.profile?.Location && profiledata?.profile?.Location?.LocationWithCountry){
      setSelected(profiledata.profile.Location)
      setVal(profiledata.profile.Location.LocationWithCountry)
    }
  }, [profiledata])
  
  const getLocation = (e) => {
    setVal(e);
    dispatch(fetchKeySearchLocation(e?.trim()));
    setFlag(false);
    setSelected(null);
    error && setError(null);
  }
  const nextHandler = () => {
    if(!selected){
      setError("Please add your current location to continue")
      setFlag(false)
    }else{
      setFlag(true);
      let update = {"Profiles.Location":selected};
      if(selected.CityId && selected.CityName && selected.RegionId && selected.CountryId){
        const Cities = {
          CityId: selected.CityId,
          CityName: selected.CityName,
          RegionId: selected.RegionId,
          CountryId: selected.CountryId,
          Latitude: selected.Latitude,
          Longitude: selected.Longitude,
        };
        const Regions = {
          RegionId: selected.RegionId,
          RegionName: selected.RegionName,
          CountryId: selected.CountryId,
          Latitude: selected.Latitude,
          Longitude: selected.Longitude,
          Code: selected.RegionCode
        };
        const Countries = {
          CountryId: selected.CountryId,
          CountryName: selected.CountryName,
        };
        update["Profiles.Cities"] = Cities;
        update["Profiles.Regions"] = Regions;
        update["Profiles.Countries"] = Countries;
        update["WizardFlow"] = profiledata?.profile?.ProfessionCode === "IT" ? 104 : 102;
      }
      dispatch(updateSignupProfile(update));
    }
  }
  return (
    <div className="NameScreenContainer" style={{minHeight:windowHeight}}>
      {typeof apiError === "string" && <Alert message={apiError} type="error" className="alert-error" showIcon closable/>}
      {profiledata?.profile?.ProfessionCode == "HC"?<div className='Profile-Completion-Parent'>
      <div className='ProfileCompletion'>
      <ProgressBar heading={"Tell us a bit about yourself"} />
      </div>
    </div> : 
     <ProfileCompletion currenPage={1} totalPage={10} title="Tell us a bit about yourself" />}
      <DropdownSelection
        title={profiledata?.profile?.ProfessionCode == "HC"?"State Licensed in": "Your Current Location"}
        placeholder={"ex. Houston, TX"}
        type={"Mandatory"}
        subTitle={profiledata?.profile?.ProfessionCode == "HC"?"Enter the state you are licensed to work in": "Pick only one option from a list of choices"}
        value={val}
        onChange={getLocation}
        options={searchedLocation}
        keyVal={"LocationWithCountry"}
        selected={selected}
        setSelected={setSelected}
        error={error}
        loading={searchLoading}
      />
      <BottomButtons 
        nextHandler={nextHandler} 
        nextBtnLoading={loading}
        backHandler={() => history.push('/name')} />
    </div>
  );
}

export default LocationScreen;
